export const devLog = (...data: any) => {
  if (process.env.NODE_ENV === "development") {
    console.log(data);
  }
};

export const devLogError = (...data: any) => {
  if (process.env.NODE_ENV === "development") {
    console.error(data);
  }
};

export const emailAddressPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  message: "Invalid email address.",
};
