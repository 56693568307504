export const endpoints = {
  LOGIN: "/token/",
  SIGNUP: "/rest-auth/registration/",
  PROFILE: "/api/user_profile/",
  PERSON: "/api/persons/",
  CAMPAIGNS: "/api/campaigns/",
  CAMPAIGN_STEPS: "/api/campaign-steps/",
  CAMPAIGN_MEMBERSHIP: "/api/campaign-membership/",
  MAIL_ACCOUNT: "/api/mail-accounts/",
  EMAILS: "/api/emails/",
  EMAIL_TEMPLATES: "/api/email-templates/",
};
