// assets
import { IconKey, IconUsers, IconMail, IconSend } from "@tabler/icons-react";

// constant
const icons = {
  IconKey,
  IconUsers,
  IconMail,
  IconSend,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: "pages",
  // title: "Pages",
  // caption: "Pages Caption",
  type: "group",
  children: [
    {
      id: "persons",
      title: "Persons",
      type: "item",
      url: "/persons",
      icon: icons.IconUsers,
      breadcrumbs: false,
    },
    {
      id: "campaigns",
      title: "Campaigns",
      type: "item",
      url: "/campaigns",
      icon: icons.IconSend,
      breadcrumbs: false,
    },
    {
      id: "mail-accounts",
      title: "Mail Accounts",
      type: "item",
      url: "/mail-accounts",
      icon: icons.IconMail,
      breadcrumbs: false,
    },
  ],
};

export default pages;
