// material-ui
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

type LogoTypes = {
  logoUrl?: any;
  width?: number | string;
};

const Logo = ({ logoUrl, width = 100 }: LogoTypes) => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    /*<img src={logoUrl} alt="Campaigns" width={width} />*/
    <Typography
      variant="h1"
      sx={{ color: theme.palette.grey["600"] }}
      style={{ textDecoration: "none" }}
    >
      Campaigns
    </Typography>
  );
};

export default Logo;
